/* src/components/CreatorForm.css */

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

:root {
  --card-bg: white;
  --text-color: #000000;
  --text-secondary: #787A7C;
  --text-tertiary: #000000;
  --button-gradient: linear-gradient(180deg, #006FC9 0%, #006FC9 100%);
  --button-hover-gradient: linear-gradient(180deg, #006FC9 0%, #00569c 100%);
  --background-text-opacity: 0.2;
  --bg-gradient: linear-gradient(180deg, #C5CF9E 0%, #579E73 100%);
  --header-bg: #ffffff;
  --border-color: #ccc;
  --label-color: #000000;
  --note-color: #afafaf;
  --page-bg: #ffffff;
  --input-bg: #ffffff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --card-bg: #121212;
    --text-color: #ffffff;
    --text-secondary: #787A7C;
    --text-tertiary: #ffffff;
    --button-gradient: linear-gradient(180deg, #1C93E3 0%, #1C93E3 100%);
    --button-hover-gradient: linear-gradient(180deg, #1C93E3 0%, #00569c 100%);
    --background-text-opacity: 0.2;
    --bg-gradient: linear-gradient(180deg, #000000 0%, #000000 100%);
    --header-bg: #121212;
    --border-color: #333;
    --label-color: #ffffff;
    --note-color: #787A7C;
    --page-bg: #121212;
    --input-bg: #2d2d2d;
  }
}

body {
  background-color: var(--page-bg);
  margin: 0;
  min-height: 100vh;
}

.page-container {
  min-height: 100vh;
  background: #ffffff;
  color: #1E1E1E;
  font-family: 'Lexend', -apple-system, BlinkMacSystemFont, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

.site-header {
  width: 100%;
  background: #ffffff;
  padding: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.header-content {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
}

.header-logo {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0rem;
  color: #53A4E1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-logo h1 {
  letter-spacing: -0.05em;
  margin-bottom: 0.1rem;
  font-weight: 900;
}

.powered-by {
  font-size: 0.8rem;
  padding: 0 rem 0.5rem;
}

.creator-form-container {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 0rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}

.creator-form-container h1 {
  font-size: 2.5rem;
  font-weight: 900;
  color: #1E1E1E;
  margin-bottom: 1rem;
  letter-spacing: -0.02em;
  line-height: 1.1;
}

.creator-form-container p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.5;
  width: 100%;
}

form {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.form-field {
  width: 100%;
  margin-bottom: 1.5rem;
  text-align: left;
  box-sizing: border-box;
}

.form-note {
  font-size: 0.75rem;
  color: #666;
  margin-top: 0.5rem;
  font-style: italic;
  letter-spacing: -0.02em;
}

label {
  display: block;
  font-weight: 500;
  margin-bottom: 0.375rem;
  color: #1E1E1E;
  font-size: 0.875rem;
  letter-spacing: -0.02em;
}

input[type="text"],
input[type="email"],
input[type="date"] {
  width: 100%;
  box-sizing: border-box;
  padding: 0.875rem 1.25rem;
  border: 1px solid #E5E7EB;
  border-radius: 100px;
  font-size: 0.875rem;
  font-family: 'Lexend', sans-serif;
  transition: all 0.2s ease;
  background: #ffffff;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="date"]:focus {
  outline: none;
  border-color: #53A4E1;
  box-shadow: 0 0 0 4px rgba(83, 164, 225, 0.1);
}

input[type="date"] {
  width: 100%;
  box-sizing: border-box;
  padding: 0.875rem 1.25rem;
  border: 1px solid #E5E7EB;
  border-radius: 100px;
  font-size: 0.875rem;
  font-family: 'Lexend', sans-serif;
  transition: all 0.2s ease;
  background: #ffffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 48px;
  cursor: pointer;
}

/* Date input specific styles */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  padding: 0.875rem 1.25rem;
}

input[type="date"]::-webkit-datetime-edit {
  padding: 0;
}

input[type="date"]::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

button[type="submit"] {
  width: 100%;
  font-family: 'Lexend', sans-serif;
  padding: 0.875rem 1.5rem;
  font-size: 1rem;
  background: linear-gradient(90deg, #29A6E7 0%, #00629E 100%);
  color: white;
  border: none;
  border-radius: 100px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease;
  letter-spacing: -0.02em;
  box-shadow: 30px 28px 16px 0px rgba(0, 0, 0, 0.01), 
              17px 16px 14px 0px rgba(0, 0, 0, 0.05), 
              8px 7px 10px 0px rgba(0, 0, 0, 0.09), 
              2px 2px 6px 0px rgba(0, 0, 0, 0.10);
}

button[type="submit"]:hover {
  background: linear-gradient(90deg, #29A6E7 0%, #003352 100%);
  transform: translateY(-2px);
}

button[type="submit"]:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.success-message {
  text-align: center;
  padding: 2rem 1rem;
  width: 100%;
}

.success-message h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.success-message p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.site-footer {
  width: 100%;
  background: #ffffff;
  padding: 2rem 0;
  margin-top: auto;
  box-sizing: border-box;
}

.footer-content {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.footer-left {
  display: flex;
  flex-direction: column;
}

.footer-title {
  font-weight: 500;
  color: #1E1E1E;
  margin-bottom: 0.5rem;
}

.footer-handle {
  color: #53A4E1;
  text-decoration: none;
  font-weight: 700;
}

.footer-center {
  text-align: center;
}

.footer-logo {
  color: #53A4E1;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.footer-right {
  text-align: right;
}

.footer-copyright {
  color: #9CA3AF;
  font-size: 0.875rem;
}

.footer-year {
  margin-left: 0.5rem;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.instagram-link {
  color: #53A4E1;
  text-decoration: none;
  transition: color 0.2s ease;
}

.instagram-icon {
  width: 24px;
  height: 24px;
}

/* Phone input customization */
.react-tel-input {
  width: 100%;
  box-sizing: border-box;
}

.react-tel-input .form-control {
  width: 100% !important;
  box-sizing: border-box !important;
  height: auto !important;
  padding: 0.875rem 1.25rem !important;
  padding-left: 3rem !important;
  border: 1px solid #E5E7EB !important;
  border-radius: 100px !important;
  font-size: 0.875rem !important;
  font-family: 'Lexend', sans-serif !important;
}

.react-tel-input .form-control:focus {
  outline: none !important;
  border-color: #53A4E1 !important;
  box-shadow: 0 0 0 4px rgba(83, 164, 225, 0.1) !important;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  background: transparent !important;
  border-radius: 100px 0 0 100px !important;
}

@media (max-width: 768px) {
  .creator-form-container {
    padding: 3rem 1.5rem;
  }

  .creator-form-container h1 {
    font-size: 2.5rem;
  }

  .creator-form-container p {
    font-size: 1.125rem;
  }

  .footer-content {
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }

  .footer-left,
  .footer-right {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .page-container {
    padding: 0;
  }

  .site-header {
    padding: 0rem 2rem;
  }

  .header-content,
  .creator-form-container,
  .footer-content {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0rem;
  }

  .form-field {
    width: 100%;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"],
  .react-tel-input .form-control {
    width: 100%;
    min-width: 0;
    font-size: 16px; /* Prevent iOS zoom */
    min-height: 48px;
    padding: 0.875rem 1.25rem;
  }

  input[type="date"] {
    min-width: 100%;
    max-width: 100%;
    padding-right: 1.25rem;
  }

  .creator-form-container h1 {
    font-size: 2rem;
  }

  .creator-form-container p {
    font-size: 0.875rem;
  }

  button[type="submit"] {
    padding: 0.75rem 1.25rem;
  }
}

.footer-social {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-instagram-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.logo-link {
  text-decoration: none;
  color: inherit;
}

.logo-link:hover {
  opacity: 0.9;
}